
const FILTER_TYPE = {
  topic: 'topic',
};

export const GENERIC_FASHION_TICKET_TOPIC = 'fashion.generic';

function buildLeaves(arr, topics, parentTopics, pos, childDepth, translate) {
  if (pos >= childDepth) {
    return;
  }

  const leafIndex = arr.findIndex(element => element.value === topics[pos]);
  const idTopic = !parentTopics ? topics[pos] : `${parentTopics}.${topics[pos]}`;

  if (leafIndex === -1) {
    const translatedTopic = translate(`ticket_topic_${topics[pos]}.other`);
    let translatedSubLabel = translate(`ticket_topic_${topics[pos]}_sub_label.other`);
    // show sub labels (granularities) only for defecitve_product topics
    if (translatedSubLabel === `ticket_topic_${topics[pos]}_sub_label.other` || !parentTopics.includes('defective_product')) {
      translatedSubLabel = null;
    }
    arr.push({
      id: idTopic,
      label: translatedTopic,
      subLabel: translatedSubLabel,
      type: FILTER_TYPE.topic,
      value: topics[pos],
      children: [],
    });
  }

  const node = leafIndex === -1 ? arr[arr.length - 1] : arr[leafIndex];

  buildLeaves(node.children, topics, idTopic, pos + 1, childDepth, translate);
}

export function removeEmptyChildren(tree) {
  if (!tree.length) {
    return tree;
  }
  for (let i = 0; i < tree.length; i += 1) {
    const branch = tree[i];
    removeEmptyChildren(branch.children);
    if (!branch.children.length) {
      delete branch.children;
    }
  }
  return tree;
}

export function buildTree(topics, translate) {
  const topicsMatrix = topics.map(topic => topic.split('.'));
  const tree = topicsMatrix.reduce((treeAcc, topicArray) => {
    buildLeaves(treeAcc, topicArray, '', 0, topicArray.length, translate);
    return treeAcc;
  }, []);

  return tree;
}

export function getSelectedTicketTopicValues(element, result) {
  if (!element.children || !element.children.length) {
    result.push(element.id);
    return result;
  }

  for (let i = 0; i < element.children.length; i += 1) {
    getSelectedTicketTopicValues(element.children[i], result);
  }
  return result;
}

export function getTranslatedTopics(topics, translate) {
  return topics.map(topic => ({
    value: topic,
    text: topic.split('.').map(splittedTopic => translate(`ticket_topic_${splittedTopic}.other`)).join(' > '),
  }));
}

export function getTreeNodeById(node, nodeId) {
  if (node.id === nodeId) {
    return node;
  }
  if (node.children && node.children.length > 0) {
    let foundNode = null;
    for (let i = 0; foundNode === null && i < node.children.length; i += 1) {
      foundNode = getTreeNodeById(node.children[i], nodeId);
    }
    return foundNode;
  }
  return null;
}

export function getLevelOfNode(node, nodeId, depth = 1) {
  if (node.id === nodeId) {
    return depth;
  }
  if (node.children && node.children.length > 0) {
    let foundNode = -1;
    const nextDepth = depth + 1;
    for (let i = 0; foundNode === -1 && i < node.children.length; i += 1) {
      foundNode = getLevelOfNode(node.children[i], nodeId, nextDepth);
    }
    return foundNode;
  }
  return -1;
}

export function getFullTranslatedTopicPath(ticketTopicId, translateFn) {
  const parts = ticketTopicId.split('.');
  return parts.reduce((translatedString, topic, ix) => `${translatedString}${ix > 0 ? ' >' : ''} ${translateFn('ticket_topic', topic)}`, '');
}


export function getStepTitleTranslationKey(topic, stepLevel, userRole) {
  // Usually, we almost always show "Which part of the device is broken?" on level 3
  // because until January 2025 we only went to level 3 for broken devices topics
  // However, since new more granular topics came, for
  // wrong_configuration_or_wrong_device we need to ask a different question - "Tell us more"
  if (stepLevel === 3 && topic.includes('wrong_configuration_or_wrong_device')) {
    return 'ticket_topic_tell_us_more.other';
  }

  return `ticket_topic_question_level_${stepLevel}_${userRole}.other`;
}
